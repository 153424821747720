@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-black text-white;
}

.border-white {
  border-color: #FFFFFF;
}

.shadow-white {
  box-shadow: 0 2px 4px 0 rgba(255, 255, 255, 0.1);
}

.bg-page-bg {
  background-color: #000000;
}

.bg-card-bg {
  background-color: #000000;
}

.bg-textarea-bg {
  background-color: #000000;
}

@import 'pixel-retroui/dist/index.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Minecraft';
  src: url('/public/minecraft.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Minecraft', sans-serif;
}

.scrollbar-pixel {
  scrollbar-color: #fff #000;
  scrollbar-width: thin;
}

.scrollbar-pixel::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scrollbar-pixel::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.scrollbar-pixel::-webkit-scrollbar-track {
  background-color: #000;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.scrollbar-pixel::-webkit-scrollbar-corner {
  background-color: #000;
}

.scrollbar-pixel::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.06) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.12) 1px, transparent 1px);
  background-size: 4px 4px;
}

.scrollbar-pixel::-webkit-scrollbar-track {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.06) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.12) 1px, transparent 1px);
  background-size: 4px 4px;
}

.max-h-screen {
  scroll-snap-type: y proximity;
  overflow-y: auto;
  height: 100vh;
}

.flex.flex-col.items-center.justify-center.min-h-screen {
  height: 100vh; /* Убедимся, что все блоки имеют одинаковую высоту */
  scroll-snap-align: center;
}

header {
  position: sticky;
  top: 0;
}

.bg-hero {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: opacity 1s;
}

.fade-in {
  opacity: 1;
  transition: opacity 1s;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s;
}